
	.BaseColor { // css vars
		--height : 2em;
		//--width  : 2em;
		--padding: 0.4em;
	}

	.pattern--checkerboard {
		@deepColor: rgba(0, 0, 0, .2);
		@cubeSize: 5px;
		background-color: white;
		background-image:
		linear-gradient(45deg,
			@deepColor 25%,
			transparent 25%,
			transparent 75%,
			@deepColor 75%,
			@deepColor
		),
		linear-gradient(45deg,
			@deepColor 25%,
			transparent 25%,
			transparent 75%,
			@deepColor 75%,
			@deepColor
		);
		background-size: (@cubeSize * 2) (@cubeSize * 2);
		background-position: 0 0, @cubeSize @cubeSize;
	}

	.BaseColor { // debug
		&__swatchWrapper,
		&__separator,
		&__input {
			//outline: 1px solid red;
		}
	}
	.BaseColor { // layout
		display: inline-block;
		flex-shrink: 0;

		&__inner {
			display: flex;
		}

		&__swatchWrapper {
			position: relative;
			flex-shrink: 0;
			display: flex;
			width: calc( var(--height)*2 - var(--padding)*0 );
			//margin-top: var(--padding);
			//margin-bottom: var(--padding);
			margin-right: calc( var(--padding) * -1 );
			border-top-left-radius: var(--borderRadius);
			border-bottom-left-radius: var(--borderRadius);
			overflow: hidden;
		}
		&__swatchPresentation {
			flex-grow: 1;
		}
		&__swatchInput {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			width: 100%;
    		height: 100%;
			opacity: 0;
			cursor: pointer;
			margin: 0;
		}

		&__separator {
			position: relative;
			height: 100%;
			width: var(--borderWidth);
			border-right: none;
			//border-right-style: var(--borderStyle);
			//border-right-color: var(--borderColor);
			//opacity: var(--borderOpacity);
			margin: 0 var(--padding);
		}

		&__input {
			height: 100%;
			//margin: var(--padding);
			flex-grow: 1;
			text-indent: 0;
		}
		//&__input--solidColorValue { width: 10em; }
		//&__input--alphaValue { width: 4em; text-align: right; }

		&__debug {
			padding-top: 0.5em;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 0.5em;
			min-width: 300px;
		}
		&__debug pre {
			margin: 0;
		}
	}
	.BaseColor { // styling
		&__swatchWrapper {}
	}

	.BaseColor { // hover
	}
	.BaseColor { // focus
	}
	.BaseColor { // disabled
	}
