
//@import (reference) "../../less/vars.less";
//@import (reference) "../../less/mixins.less";
//@import (reference) "../../less/atoms.less";

.BaseFileInput { // vars
	--transition : all 0.15s ease;

	--height	   : 30px;
	--paddingLeft  : 0.85em;
	--paddingRight : 0.85em;

	--fontFamily   : inherit;
	--fontWeight   : inherit;

	--borderRadius : 15px;
	--borderWidth  : 2px;
	--borderStyle  : solid;
	--borderColor  : blue;

	--filled-bgColor	 : blue;
	--filled-textColor   : white;
	--outlined-bgColor   : fade( blue, 10 );
	--outlined-textColor : blue;

	--hover-color			: darken( blue, 5 );
	--hover-outlined-bgColor : fade( blue, 20 );

	--active-outlined-bgColor : fade( blue, 30 );
	--active-transform : translateY(2px);
}
.BaseFileInput { // debug
	[showBorders2] & {}

	&__inner > * {
		//outline: 1px solid red;
		//background-color: fade( red, 25 );
	}
}
.BaseFileInput { // layout
	height: var(--height);
	display: inline-block;
	overflow: hidden;

	&__inner {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%;
		//overflow: hidden; // musste raus, da in firefox ansonsten das input[type=file] nicht klickbar war
	}
	&__slot {
		//display: none !important;
		display: flex;
		max-height: 100%;
	}
	&__slot:empty { display: none; }
	&__slot--text { flex-grow: 1; }
	&__slot--text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__input {
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		opacity: 0;
		z-index: 1;
		cursor: pointer;
		overflow: hidden;

		&::-webkit-file-upload-button {
			cursor: pointer;
		}
	}

}
.BaseFileInput { // styling
	outline: none;
	border: none;
	background-color: transparent;
	font-family: var(--fontFamily);
	font-weight: var(--fontWeight);
	line-height: 1em; //var(--height); // this v-centers the text
	user-select: none;
	overflow: visible; // needed for not cutting the :active translateY style
	cursor: pointer;

	&__inner {
		transition: var(--transition);
		border-radius: var(--borderRadius);
		padding-left: var(--paddingLeft);
		padding-right: var(--paddingRight);
	}

	&__slot--text { justify-content: center; }
	&__slot--before { transform: translateX( -0.25em ); padding: 0 0.25em; }
	&__slot--after { transform: translateX( 0.25em ); padding: 0 0.25em; }

	&--isFilled &__inner { background-color: var(--filled-bgColor); }
	&--isFilled { color: var(--filled-textColor); }

	&--isOutlined &__inner { background-color: var(--outlined-bgColor); border: var(--borderWidth) var(--borderStyle) var(--borderColor); }
	&--isOutlined { color: var(--outlined-textColor); }

	&--isDisabled { opacity: 0.5; pointer-events: none; }
	&--isOutlined&--isDisabled &__inner { background-color: none; border-style: dotted; }

	&:hover&--isFilled &__inner { background-color: var(--hover-color); }

	&:hover&--isOutlined &__inner { background-color: var(--hover-outlined-bgColor); border-color: var(--hover-color); }
	&:hover&--isOutlined { color: var(--hover-color); }

	&:active &__inner { transform: var(--active-transform); }
	&:active&--isOutlined &__inner { background-color: var(--active-outlined-bgColor); }
}

//@media @mediaQueries[xs] {}
//@media @mediaQueries[sm] {}
//@media @mediaQueries[md] {}
//@media @mediaQueries[dt] {}
//@media @mediaQueries[lg] {}
//@media @mediaQueries[xl] {}
